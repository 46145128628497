var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", "max-width": "600px" },
      on: { "click:outside": _vm.close }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "" } },
            [
              _c(
                "v-toolbar-title",
                [_c("v-card-title", [_vm._v(" Warnings / Errors ")])],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-2 pb-1" },
            [
              _vm.selectedContract.warning_reason
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-3 mb-4",
                      attrs: { color: "orange lighten-3" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("v-icon", { staticClass: "ml-2 mr-4" }, [
                                _vm._v("mdi-alert")
                              ])
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "11" } }, [
                            _vm._v(
                              " Warning: " +
                                _vm._s(_vm.selectedContract.warning_reason) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mt-4",
                                              attrs: { outlined: "" },
                                              on: {
                                                click: _vm.downloadWarningLog
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(" Download Log "),
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v(" mdi-file-outline ")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                432194788
                              )
                            },
                            [_c("span", [_vm._v("Download warning log file")])]
                          ),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedContract.failure_reason
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-3 mb-4",
                      attrs: { color: "red lighten-3" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("v-icon", { staticClass: "ml-2 mr-4" }, [
                                _vm._v("mdi-close-box")
                              ])
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "11" } }, [
                            _vm._v(
                              " Error: " +
                                _vm._s(_vm.selectedContract.failure_reason) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }